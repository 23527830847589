import { render, staticRenderFns } from "./driverlist.vue?vue&type=template&id=0368c158&scoped=true&"
import script from "./driverlist.vue?vue&type=script&lang=js&"
export * from "./driverlist.vue?vue&type=script&lang=js&"
import style0 from "./driverlist.vue?vue&type=style&index=0&id=0368c158&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0368c158",
  null
  
)

export default component.exports